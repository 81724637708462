<template>
  <div class="background--modal-landlord" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <div slot="start">
        <ion-button
          v-if="selectedView === '1'"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal()"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView = '1'"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
      </div>
      <div>
        <ion-title
          class="text--white text-18"
        >
          Gateway Setup
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="platform.includes('desktop')">
      <div style="margin-top: 20vh">
        <ion-text class="text--primary text-22">
          <i
            class="mdi mdi-alert-circle"
            style="font-size: 30px;"
          />
          This action can be done only on mobile phone
        </ion-text>
        <div class="mt-30">
          <ion-button
            style="max-width: 300px;"
            @click="dismissModal()"
          >
            Dismiss
          </ion-button>
        </div>
      </div>
    </div>
    <div v-else>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="selectedView === '1'"
          key="1"
        >
          <div style="padding-bottom: 50px;">
            <img
              :src="require('@/assets/images/homevision.svg')"
            >
          </div>
          <ion-row class="mt-10">
            <ion-col class="text-center welcome-text-intro text--white">
              Please select gateway model <br><br>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <div
                :class="selectedGatewayModel === 'SEG-X3' ? 'active' : ''"
                class="model-button rounded-corners"
                @click="selectedGatewayModel = 'SEG-X3'"
              >
                <img
                  class="mt-20"
                  style="max-width: 100px;"
                  :src="require('@/assets/devices/gateway.png')"
                >
                <div
                  class="text--primary mt-10"
                >
                  <!-- Wireless Gateway -->
                </div>
              </div>
            </ion-col>
            <ion-col>
              <div
                class="model-button rounded-corners"
                :class="selectedGatewayModel === 'SEG-X5' ? 'active' : ''"
                @click="selectedGatewayModel = 'SEG-X5'"
              >
                <img
                  style="max-width: 135px; margin-top: 35px;"
                  :src="require('@/assets/devices/gateway2.png')"
                >
                <div
                  class="text--primary mt-20"
                >
                  <!-- Wired Gateway -->
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-button
            v-show="selectedGatewayModel"
            class="continue"
            @click="checkPermissions"
          >
            <span v-if="!loading">continue</span>
            <ion-spinner
              v-else
              color="light"
              name="crescent"
            />
          </ion-button>
        </div>
        <div
          v-else-if="selectedView === '2'"
          key="2"
        >
          <div v-if="permissionGranted === true && permissionLoading === false">
            <div v-if="selectedGatewayModel === 'SEG-X3'">
              <ion-row>
                <ion-col>
                  <ion-text class="text--bold text-larger text--white">
                    Your home WiFi
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-text class="welcome-text-intro text--gray">
                    The name of the WiFi (SSID) network should contain only numbers and letters.
                    The gateway supports 2.4 GHz connection. <br><br>
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row class="margin-5vh-top">
                <ion-col
                  class="form--dark"
                  :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
                >
                  <ion-item
                    lines="none"
                    class="ion-margin-bottom input"
                  >
                    <ion-label position="floating">
                      Your WiFi SSID e.g. 'dlink-4EC8'
                    </ion-label>
                    <ion-input
                      v-model="selectedWifi"
                      :value="selectedWifi"
                      enterkeyhint="done"
                      @keydown.enter="closeKeyboard"
                    />
                  </ion-item>
                  <ion-item
                    lines="none"
                    class="ion-margin-bottom input"
                  >
                    <!-- @input="selectedWifiPassword=$event.target.value" -->
                    <ion-label position="floating">
                      Wifi password
                    </ion-label>
                    <ion-input
                      v-model="selectedWifiPassword"
                      :value="selectedWifiPassword"
                      enterkeyhint="done"
                      @keydown.enter="closeKeyboard"
                    />
                  </ion-item>
                </ion-col>
                <ion-label class="text--primary">
                  The SSID and Wifi Password can be found on back of your router
                </ion-label>
              </ion-row>
              <ion-button
                v-show="selectedWifiPassword"
                class="mt-10"
                @click="nextStep"
              >
                <ion-label>Connect to Gateway</ion-label>
              </ion-button>
            </div>
            <div v-else-if="selectedGatewayModel === 'SEG-X5'">
              <div>We have all we need now, please connect to the gateway</div>

              <div
                v-if="!canContinue && loading"
                class="text--gray"
              >
                <ion-spinner
                  color="light"
                  name="crescent"
                /><br><br>
                {{ $t('Loading') }}....
              </div>

              <div
                v-if="noX5Found"
                class="text--primary mb-10"
              >
                It seems there are no gateways found, is your gateway powered on and in pairing mode?
              </div>
              <ion-button
                class="mt-10"
                @click="nextStep"
              >
                <ion-label>Connect to Gateway</ion-label>
              </ion-button>
            </div>
          </div>
          <div v-else-if="permissionGranted === false && permissionLoading === false">
            <div v-if="platform.includes('ios')">
              <i
                class="mdi mdi-map-marker-alert text--primary"
                style="font-size: 50px;"
              />
              <div class="mt-30">
                {{ $t('We need some additional premissions in order to continue the gateway setup.') }} <br><br>
                {{ $t('iosPermissionsDescription') }}
                <ion-button
                  class="mt-30"
                  @click="requestPermissionsIos"
                >
                  <ion-label>{{ $t('Allow Location') }}</ion-label>
                </ion-button>
              </div>
            </div>
            <div v-else-if="platform.includes('android')">
              <div class="text--white">
                <i
                  class="mdi mdi-map-marker-alert text--primary"
                  style="font-size: 50px;"
                />
                <div class="mt-30">
                  {{ $t('We need some additional premissions in order to continue the gateway setup.') }} <br><br>
                  {{ $t('Please continue by clicking button') }} <br> <span class="text--primary">{{ $t('Request Permissions') }}</span>
                </div>
              </div>
              <ion-button
                class="mt-30"
                @click="requestPermissionsAndroid"
              >
                <ion-label>{{ $t('Request Permissions') }}</ion-label>
              </ion-button>
            </div>
            <div v-else>
              Not allowed
            </div>
          </div>
          <div v-else-if="permissionLoading === true">
            <div
              class="text--gray"
            >
              <ion-spinner
                color="light"
                name="crescent"
              /><br><br>
              {{ $t('Loading') }}....
            </div>
          </div>
        </div>
        <div v-else-if="selectedView === '3'">
          <ion-row>
            <ion-col>
              <ion-text class="text--bold text-larger text--white">
                Begin gateway setup
              </ion-text>
            </ion-col>
          </ion-row>
          <div v-if="selectedGatewayModel === 'SEG-X3'">
            <ion-row>
              <ion-col>
                <ion-text class="welcome-text-intro text--gray">
                  Now all is set. <br>We need you to connect to the gateway wifi.
                  Please connect to wifi name containing "ZB_GW"
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-button
              class="margin-5vh-top"
              @click="connectToGatewayWifi"
            >
              <ion-label>Connect to "ZB_GW" wifi</ion-label>
            </ion-button>
            <ion-button
              v-show="connectClicked && !gwAddFailed"
              class="mt-10"
              color="success"
              @click="isConnectedToGatewayWifi"
            >
              <ion-label>Start gateway setup</ion-label>
            </ion-button>
          </div>
          <div v-else-if="selectedGatewayModel === 'SEG-X5' ">
            <ion-button
              class="mt-10"
              color="success"
              @click=" selectedView = '4'; setupProcess"
            >
              <ion-label>Start gateway setup</ion-label>
            </ion-button>
          </div>
          <div class="mt-10">
            <ion-text
              v-if="gwAddFailed"
              color="primary"
            >
              Configuring gateway failed. Please make sure your gateway is rebooted and flashing purple and you are connected to the "ZB_GB" wifi
            </ion-text>
          </div>
          <ion-button
            v-show="gwAddFailed"
            class="mt-10"
            @click="repeatSetup"
          >
            <ion-label>Try again gateway setup</ion-label>
          </ion-button>
        </div>
        <div v-else-if="selectedView === '4'">
          <ion-row>
            <ion-col>
              <ion-text class="text--bold text-larger text--white">
                Configuring gateway
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-text class="text--gray">
                We have all we need now. <br>Configuring the gateway..hold tight!
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="configure-gateway">
            <ion-col>
              <div
                v-show="loader"
              >
                <div
                  class="loader-wrapper"
                >
                  <div class="back-circle-loader">
                    <div class="loader-anim" />
                    <div class="inner-loader" />
                    <div class="inner-loader-frame" />
                  </div>
                  <div class="laoder-percentage">
                    <span :class="gwAddFailed ? 'text--danger' : ''">{{ progress }}%</span>
                  </div>
                </div><br>
                <ion-label class="setup-status">
                  {{ status }}
                </ion-label>
              </div>
            </ion-col>
          </ion-row>
          <ion-button
            v-show="gatewayAdded"
            class="margin-5vh-top"
            color="success"
            @click="allDone"
          >
            <ion-label>Done!</ion-label>
          </ion-button>
          <ion-button
            v-show="gwAddFailed"
            class="margin-5vh-top"
            color="danger"
            @click="previousView"
          >
            <ion-label>Try again</ion-label>
          </ion-button>
        </div>
      </transition>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'
import { loadingController } from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'
import { WifiWizard2 } from '@ionic-native/wifi-wizard-2/'
import { AndroidPermissions } from '@ionic-native/android-permissions'
import { Diagnostic } from '@ionic-native/diagnostic'
import { Insomnia } from '@ionic-native/insomnia'

import { Zeroconf } from '@ionic-native/zeroconf'

import tcpController from '../../lib/tcpController'

export default defineComponent({
  name: 'Popover',
  props: {
    refreshData: { type: Function, default: null },
    close: { type: Function, default: null },
    apartment: { type: Object, default: () => {} }
  },
  data () {
    return {
      wifiWizard2: WifiWizard2,
      insomnia: Insomnia,
      loading: false,
      loader: false,
      created: false,
      selectedView: '1',
      selectedWifi: '',
      selectedWifiPassword: '',
      message: '',
      status: '',
      gatewayIp: '192.168.1.1',
      tcpPort: null,
      connectClicked: false,
      progress: 0,
      gwAddFailed: false,
      gatewayAdded: false,
      connectedWifi: '',
      ready: false,
      attempts: 0,
      loaderPreset: false,
      androidPermissions: AndroidPermissions,
      diagnostic: Diagnostic,
      permissionLoading: true,
      permissionGranted: false,
      selectedGatewayModel: '',
      canContinue: false,
      noX5Found: false
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    tcpSocket () {
      return this.$store.state.socketTcpId
    },
    tcpData () {
      return this.$store.state.tcpData
    },
    gwSessionId () {
      return this.$store.state.gwSessionId
    },
    tcpStatus () {
      return this.$store.state.tcpStatus
    }
  },
  created () {
    document.addEventListener('deviceready', (i) => {
      this.ready = true
    })
  },
  methods: {
    dismissModal () {
      this.close()
    },
    async progressPlus (number) {
      for (let i = 0; i < number; i++) {
        await new Promise(resolve => setTimeout(resolve, 100))
        this.progress++
      }
    },
    connectToGatewayWifi () {
      window.cordova.plugins.settings.open('wifi', () => {
        console.log('wifi settings opened')
      })
      this.connectClicked = true
    },
    checkPermissions () {
      this.permissionLoading = true
      this.loading = true
      if (this.platform.includes('android')) {
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
          result => {
            console.log('Android status permissions')
            console.log('PermissionResult          ' + JSON.stringify(result))
            if (!result.hasPermission) {
              this.permissionGranted = false
              this.loading = false
              setTimeout(() => {
                this.permissionLoading = false
                this.selectedView = '2'
              }, 500)
            } else {
              this.permissionGranted = true
              this.loading = false
              setTimeout(() => {
                this.permissionLoading = false
                this.selectedView = '2'
              }, 500)
            }
          },
          err => {
            console.log(err)
            this.permissionLoading = false
            this.loading = false
          }
        )
      } else if (this.platform.includes('ios')) {
        this.permissionLoading = true
        this.diagnostic.isLocationAuthorized().then((isEnabled) => {
          if (isEnabled) {
            this.permissionGranted = true
            console.log('location enabled')
            setTimeout(() => {
              this.permissionLoading = false
              this.loading = false
              this.selectedView = '2'
            }, 500)
          } else {
            this.permissionGranted = false
            this.loading = false
            console.log('location disabled')
            setTimeout(() => {
              this.permissionLoading = false
              this.selectedView = '2'
            }, 500)
          }
        })
      } else {
        console.log('others')
      }
    },
    async whatIsConnectedTo () {
      if (this.ready) {
        try {
          this.wifiWizard2.getConnectedSSID()
            .then(response => {
              this.success = response
              console.log(response)
              this.connectedWifi = response
            })
            .catch(error => {
              this.error = error
              console.log(error)
            })
        } catch (error) {
          this.error = error
        }
      } else {
        this.error = 'not ready'
      }
    },
    async isConnectedToGatewayWifi () {
      this.whatIsConnectedTo()
      console.log('repeating')
      if (!this.loaderPreset) {
        this.wifiLoader()
      }
      if (this.attempts <= 100) {
        if (this.connectedWifi.includes('ZB_GW')) {
          this.status = ''
          this.gwAddFailed = false
          this.progress = 0
          this.dismissLoading()
          this.setupProcess()
          this.attempts = 0
          this.selectedView = '4'
        } else {
          console.log('Not connected')
          this.attempts++
          this.repeateFunction()
        }
      } else {
        this.dismissLoading()
        this.attempts = 0
        this.status = 'Failed, you are not connected to Gateway'
      }
    },
    repeatSetup () {
      this.status = ''
      this.gwAddFailed = false
      this.progress = 0
      this.dismissLoading()
      this.setupProcess()
      this.attempts = 0
      this.selectedView = '4'
    },
    repeateFunction () {
      setTimeout(() => { this.isConnectedToGatewayWifi() }, 1000)
    },
    async wifiLoader () {
      this.loaderPreset = true
      const loading = await loadingController
        .create({
          cssClass: 'gateway-disconnected-overlay',
          message: 'Checking weather connected to gateway',
          spinner: 'crescent'
        })

      await loading.present()

      const interval = setInterval(() => { loading.message = `${this.attempts}% Checking weather connected to gateway` }, 1000)
      setTimeout(() => { clearInterval(interval) }, 100000)
    },
    previousView () {
      this.selectedView = '3'
    },
    allDone () {
      tcpController.closeTcp(window)
      this.close()
    },
    async setupProcess () {
      this.loader = true
      this.insomnia.keepAwake()
      if (!this.tcpSocket) {
        tcpController.initiateConnection(window)
        this.status = 'Connection initiated'
        this.progressPlus(10)
      } else {
        console.log('TCP Socket connection already craeted, skiping')
      }
      await new Promise(resolve => setTimeout(resolve, 2000))

      tcpController.connectToTheGateway(window, this.tcpSocket, this.gatewayIp, this.tcpPort, this.selectedGatewayModel)
      await new Promise(resolve => setTimeout(resolve, 1000))

      this.status = this.$t('Logging In To The Gateway')
      if (this.tcpStatus === 'error' || this.tcpStatus === 'notConnected') {
        this.gwAddFailed = true
        this.status = this.$t('Something has failed, are you connected to the gateways wifi?')
      } else if (this.tcpStatus === 'OK') {
        if (this.selectedGatewayModel === 'SEG-X3') {
          const command = '{"argument":{"password":"123456","username":"owon"},"type":"login","sequence":1017}'
          await tcpController.sendCommandToGateway(window, this.tcpSocket, command)
        }
        await new Promise(resolve => setTimeout(resolve, 1000))
        if (!this.gwAddFailed) {
          this.progressPlus(10)
          // if (this.tcpData.result) {
          if (this.selectedGatewayModel === 'SEG-X3') {
            this.$store.dispatch('gwSessionId', this.tcpData.response.session)
          } else {
            this.$store.dispatch('gwSessionId', '1peem39po1ilnk9')
          }
          this.status = this.$t('Successfully logged in')
          this.$store.dispatch('clearTcpData')
          await new Promise(resolve => setTimeout(resolve, 1000))
          const command = `{"type":"netConfig","command":"config","session":"${this.gwSessionId}","sequence":1193,"argument":{"web":"homevision.se","portNum":"4046","ipAddr":"13.53.235.209","sslPortNum":"4047"}}`
          await tcpController.sendCommandToGateway(window, this.tcpSocket, command)
          // } else {
          //   this.status = this.$t('Something has failed please try that again')
          //   this.loader = false
          //   this.gwAddFailed = true
          // }
          this.progressPlus(10)
          await new Promise(resolve => setTimeout(resolve, 1000))

          // if (this.tcpData.result) {
          if (this.selectedGatewayModel === 'SEG-X3') {
            this.status = this.$t('Setting Up Gateways Wifi')
            this.$store.dispatch('clearTcpData')
            const command2 = `{"type":"wifiConfig","command":"sta","session":"${this.gwSessionId}","sequence":1002,"argument":{"ssid":"${this.selectedWifi}","sskey":"${this.selectedWifiPassword}"}}`
            await tcpController.sendCommandToGateway(window, this.tcpSocket, command2)
          }
          // } else {
          //   this.status = this.$t('Something has failed please try that again')
          //   this.loader = false
          //   this.gwAddFailed = true
          // }
          this.progressPlus(10)
          await new Promise(resolve => setTimeout(resolve, 1000))

          // if (this.tcpData.result) {
          this.status = this.$t('Setting Up Gateways Time Zone')
          this.$store.dispatch('clearTcpData')
          const command3 = `{"type":"system","command":"setTimeZone","session":"${this.gwSessionId}","sequence":1024,"argument":{"area":"Europe/Prague","timezone":1,"dst":false}}`
          await tcpController.sendCommandToGateway(window, this.tcpSocket, command3)
          // } else {
          //   this.status = this.$t('Something has failed please try that again')
          //   this.loader = false
          //   this.gwAddFailed = true
          // }

          const now = new Date()
          const then = new Date('2000-01-01')
          const diff = now.getTime() - then.getTime()
          const currentTime = Math.round(diff / 1000)
          this.progressPlus(30)
          await new Promise(resolve => setTimeout(resolve, 1000))

          // if (this.tcpData.result) {
          this.status = this.$t('Setting Up Gateways Time')
          this.$store.dispatch('clearTcpData')
          const command4 = `{"type":"system","command":"setTime","session":"${this.gwSessionId}","sequence":1024,"argument":{"utc":${currentTime}}}`
          await tcpController.sendCommandToGateway(window, this.tcpSocket, command4)
          // } else {
          //   this.status = this.$t('Something has failed please try that again')
          //   this.loader = false
          //   this.gwAddFailed = true
          // }

          await new Promise(resolve => setTimeout(resolve, 1000))

          // if (this.tcpData.result) {
          this.status = this.$t('Launching Gateway')
          this.$store.dispatch('clearTcpData')
          const command5 = `{"type":"netConfig","command":"register","session":"${this.gwSessionId}","sequence":1195,"argument":{"username":"${this.apartment.id}","password":"123456","agentid":"${this.apartment.agentId}"}}`
          await tcpController.sendCommandToGateway(window, this.tcpSocket, command5)
          // } else {
          //   this.status = this.$t('Something has failed please try that again')
          //   this.loader = false
          //   this.gwAddFailed = true
          // }
          this.progressPlus(30)
          await new Promise(resolve => setTimeout(resolve, 30000))
          const newGateway = this.selectedGatewayModel
          this.$store.dispatch('addLandlordGateway', { newGateway, apartmentId: this.apartment.id }).then(() => {
            this.refreshData(this.apartment.id)
            this.status = 'Gateway started, please hold on..'
            this.loader = false
            this.gatewayAdded = true
          })
        }
      }
    },
    dismissLoading () {
      this.loaderPreset = false
      loadingController.dismiss()
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    requestPermissionsAndroid () {
      this.loading = true
      this.androidPermissions.requestPermissions(
        [
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        ]
      ).then(
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
          result => {
            console.log('Android status permissions')
            console.log('PermissionResult          ' + JSON.stringify(result))
            if (!result.hasPermission) {
              this.permissionGranted = false
              this.loading = false
            } else {
              this.permissionGranted = true
              this.loading = false
            }
          },
          err => {
            console.log(err)
            this.loading = false
            this.previousSlide()
          }
        )
      )
    },
    requestPermissionsIos () {
      window.cordova.plugins.settings.open('locations', () => {
        console.log('wifi settings opened')
      })
      this.permissionGranted = true
    },
    nextStep () {
      console.log('slide 3')
      this.loading = true
      if (this.selectedGatewayModel === 'SEG-X3') {
        this.gatewayIp = '192.168.1.1'
        this.tcpPort = 11500
        this.canContinue = true
        this.selectedView = '3'
      } else if (this.selectedGatewayModel === 'SEG-X5') {
        setTimeout(() => {
          if (this.canContinue === false) {
            this.noX5Found = true
          }
        }, 5000)
        Zeroconf.watch('_x5_cfg._tcp.', 'local.').subscribe(result => {
          const gwIpAddress = String(result.service.ipv4Addresses[0])
          const port = Number(result.service.port)
          if (result.action === 'resolved') {
            this.noX5Found = false
            this.gatewayIp = gwIpAddress
            this.tcpPort = port
            this.canContinue = true
            this.selectedView = '3'
          } else {
            console.log(result)
          }
        })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
@import '~@/theme/_layouts';
.tenant-detail {
   @include bg-box-item;
   --min-height: 60px;
}
.continue {
    margin-top: 10vh;
    min-height: 50px;
    max-width: 300px;
}
ion-slide {
 padding-top: 20px;
}

.welcome {
  &-content {
    top: 2%;
  }
}

.back-button {
  height: 60px;
  width: 110px;
  font-size: 25px;
}

.next-icon {
  background-color: colors(primary, base);
  padding: 8px 10px 8px 10px;
  border-radius: $radius-sm;
}

.connect-gateway {

  &-button {
    height: 250px;
    width: 250px;
    font-size: 60px;
  }

  &-icon {
    padding: 10px;
    border-radius: $radius-sm;
    background-color: rgba(0, 117, 218, 0.7);
  }
}

.slides {
  height: 100%;
  width: 100%;
}

.configure-gateway {
  margin-top: 10vh;
}

.setup-status {
  color: white;
}

ion-spinner {
  width: 40px !important;
  height: 40px !important;
}

/// Ostre jako prase////////
$offset: 30px;
.middle-content {
  justify-content: center;
  padding: 0 $offset;
  img {
    padding-bottom: $offset;
  }
}
.previous-icon {
  font-size: 30px;
  padding: 10px;
}

.input {
  --background: rgba(44, 48, 54, 0.6);
  border-radius: 5px;
}
ion-input {
  --color: white;
}

ion-select {
  color: white;
}

ion-button {
  min-height: 50px;
  --ripple-color: #{colors(primary, base)};
  --background-activated: #{colors(primary, base)};
  --background-activated-opacity: 1;
  --background-focused:#{colors(primary, base)};
  --background-focused-opacity:1;
  --background-hover: #{colors(primary, base)};
  --background-hover-opacity:1;
}
.loader-wrapper {
  position: relative;
}
.back-circle-loader {
  position: relative;
  z-index: -1;
  height: 214px;
  width: 214px;
  background: linear-gradient(180deg, #ffd5a433 0%, #f4b87421 100%);
  // opacity: 0.1;
  border-radius: 50%;
  display: inline-block;
}
.loader-anim {
  position: relative;
  top: 7px;
  left: 7px;
  border: 8px solid #f3f3f300;
  border-top: 8px solid #ffd4a4;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  -webkit-animation: spinAnim 1s linear infinite;
  animation: spinAnim 1s linear infinite;
}
@keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.laoder-percentage {
  color: white;
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 52px;
  line-height: 61px;
}
.inner-loader {
  z-index: 3;
  position: absolute;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 164px;
  width: 164px;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  filter: drop-shadow(0px 7px 11px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(42px);
  border-radius: 50%;
}
.inner-loader-frame {
  position: absolute;
  z-index: 5;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 165px;
  width: 165px;
  border-radius: 50%;
  background: radial-gradient(100% 100% at 0% 0%, rgba(206, 206, 206, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(150, 150, 150, 0.205);
}

.model-button {
  padding: 5px;
  min-height: 150px;
  @include bg-box-item;
  background-color: #{transparentize(colors(dark, shade), 0.3)};
}

.active {
  border: 1px solid colors(primary, base);
  background-color: #{transparentize(colors(primary, base), 0.9)};
}
</style>