<template>
  <div class="background--modal-landlord" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <div slot="start">
        <ion-button
          v-if="selectedView === '1' && '2'"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal(buildingId)"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView = '1'"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
      </div>
      <div>
        <ion-title
          class="text--white text-18"
        >
          {{ apartmentName }}
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="!created">
      <div class="wrapper">
        <form
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-row
            class="ion-no-padding"
            style="margin-top: -15px;"
          >
            <ion-col
              class="ion-no-padding"
              style="margin-right: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Apartment Number
                </ion-label>
                <ion-input
                  v-model="apartmentNumber"
                  type="number"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
            <ion-col
              class="ion-no-padding"
              style="margin-left: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Type
                </ion-label>
                <ion-select
                  v-model="apartmentType"
                  placeholder="Select One"
                  type="number"
                  required
                >
                  <ion-select-option
                    v-for="{option, name} in typeOptions"
                    :key="option"
                    :value="option"
                  >
                    {{ name }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row
            class="ion-no-padding"
            style="margin-top: -15px;"
          >
            <ion-col
              class="ion-no-padding"
              style="margin-right: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Apartment Name
                </ion-label>
                <ion-input
                  v-model="apartmentName"
                  type="text"

                  required
                />
              </ion-item>
            </ion-col>
            <ion-col
              class="ion-no-padding"
              style="margin-left: 5px;"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Floor
                </ion-label>
                <ion-select
                  v-model="selectedFloor"
                  placeholder="Select One"
                  type="number"
                  required
                >
                  <ion-select-option
                    v-for="floor in buildingFloors"
                    :key="floor"
                    :value="floor"
                  >
                    {{ floor }} Floor
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
        </form>
        <ion-button
          class="button-tenant mt-30"
          :disabled="loading"
          @click="saveEditedDetails"
        >
          <ion-spinner
            v-if="loading"
            name="crescent"
          />
          <span v-else>Save apartment details</span>
        </ion-button>
      </div>
    </div>
    <div
      v-else
      style="margin-top: 20vh;"
    >
      <span class="text-64 text--white"><i
        class="mdi mdi-check-circle"
        style="color: lightgreen;"
      /> Tenant Invited</span>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'
import { } from '@ionic/vue'

export default defineComponent({
  name: 'Popover',
  props: {
    refreshData: { type: Function, default: null },
    close: { type: Function, default: null },
    property: { type: Object, default: () => {} },
    building: { type: Object, default: () => {} }
  },
  data () {
    return {
      loading: false,
      created: false,
      selectedView: '1',
      apartmentId: 0,
      buildingFloors: 0,
      selectedFloor: 0,
      apartmentName: '',
      apartmentType: '',
      apartmentNumber: '',
      typeOptions: [
        { option: 'Comercial', name: 'Comercial' },
        { option: 'Residental', name: 'Residental' },
        { option: 'Utility', name: 'Utility' },
        { option: 'Common Area', name: 'Common Area' }
      ]
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    console.log(this.property)
    console.log(this.building)
    this.apartmentId = this.property.id
    this.buildingFloors = this.building.buildingFloors
    this.selectedFloor = Number(this.property.propertyFloor)
    this.apartmentName = this.property.propertyName
    this.apartmentType = this.property.propertyType
    this.apartmentNumber = this.property.flatNumber
  },
  methods: {
    saveEditedDetails () {
      this.loading = true
      this.$store.dispatch('landlordSaveEditedProperty', {
        propertyId: this.apartmentId,
        propertyFloor: this.selectedFloor.toString(),
        propertyName: this.apartmentName,
        propertyType: this.apartmentType,
        flatNumber: this.apartmentNumber
      }).then(() => {
        this.dismissModal(this.apartmentId)
      })
    },
    dismissModal (apartmentId) {
      this.refreshData(apartmentId)
      this.close()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-button {
  min-height: 50px;
}
</style>