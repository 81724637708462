<template>
  <ion-header
    class="ion-no-border"
  >
    <ion-toolbar
      v-if="selectedView === '1'"
      color="transparent"
    >
      <!-- <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="identifyDevice(device.id)"
      >
        <i
          class="material-icons"
          style="font-size: 27px"
        >not_listed_location</i>
      </ion-button> -->
    </ion-toolbar>
    <ion-toolbar
      v-if="selectedView === '2'"
      color="transparent"
    >
      <ion-item
        slot="start"
        lines="none"
        color="transparent"
        class="close-modal-button"
        @click="selectedView = '1'"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title
        class="text-18 text--white"
      >
        {{ $t('settings') }}
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="removeAlertConfirm(device)"
      >
        <i
          class="material-icons"
        >delete</i>
      </ion-button>
    </ion-toolbar>
    <ion-toolbar
      v-if="selectedView === '3'"
      color="transparent"
    >
      <ion-item
        slot="start"
        lines="none"
        color="transparent"
        class="close-modal-button"
        @click="selectedView = '1'"
      >
        <img src="../../assets/images/arrow-go-back-line.svg">
      </ion-item>
      <ion-title
        class="text-18 text--white"
      >
        {{ $t('History') }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content
    :scroll-y="true"
    class="ion-padding"
  >
    <div v-if="selectedView === '1'">
      <div v-if="device.typeI === 'SmartLightColorDimmable'">
        <div>
          <img
            style="max-width: 200px;"
            src="../../assets/images/colored-bulb-large.png"
          >
        </div>
        <ion-item
          color="transparent"
          lines="none"
          class="mt-10"
        >
          <ion-toggle
            v-model="switchgear"
            :disabled="$store.state.communication"
            style="margin: auto;"
            :checked="switchgear"
          />
        </ion-item>
        <ion-item
          lines="none"
          color="transparent"
          class="mt-30"
          style="margin-bottom: -15px"
        >
          <ion-text class="text-14 text--white text--bold">
            {{ $t('Brightness') }}
          </ion-text>
        </ion-item>
        <ion-item
          color="transparent"
          lines="none"
          class="light-intensity"
        >
          <ion-range
            v-model="lightLevel"
            debounce="300"
            class="range"
            min="0"
            max="254"
          />
        </ion-item>
        <ion-item
          lines="none"
          color="transparent"
          style="margin-bottom: -15px"
        >
          <ion-text class="text-14 text--white text--bold">
            {{ $t('Light temperature') }}
          </ion-text>
        </ion-item>
        <ion-item
          color="transparent"
          lines="none"
          class="color-temperature"
        >
          <ion-range
            v-model="colorTemp"
            class="range"
            debounce="300"
            min="1"
            max="369"
          />
        </ion-item>
      </div>
      <div v-else-if="device.typeI === 'SmartLightColorChangeable'">
        <div>
          <img
            style="max-width: 100px; margin-top: -30px;"
            src="../../assets/images/colored-bulb-large.png"
          >
        </div>
        <ion-item
          color="transparent"
          lines="none"
          class="mt-10"
        >
          <ion-toggle
            v-model="switchgear"
            :disabled="$store.state.communication"
            style="margin: auto;"
            :checked="switchgear"
          />
        </ion-item>
        <ion-item
          lines="none"
          color="transparent"
          class=""
          style="margin-bottom: -15px"
        >
          <ion-text class="text-14 text--white text--bold">
            {{ $t('Brightness') }}
          </ion-text>
        </ion-item>
        <ion-item
          color="transparent"
          lines="none"
          class="light-intensity"
        >
          <ion-range
            v-model="lightLevel"
            debounce="300"
            class="range"
            min="0"
            max="254"
          />
        </ion-item>
        <!-- <ion-item
          lines="none"
          color="transparent"
          style="margin-bottom: -15px"
        >
          <ion-text class="text-14 text--white text--bold">
            {{ $t('Light Colour') }}
          </ion-text>
        </ion-item> -->
        <!-- <ion-item
          color="transparent"
          lines="none"
        >
          <ion-range
            v-model="value"
            class="range"
            :style="colorSelectBg"
            debounce="300"
            min="0"
            max="100"
            @ionChange="changeValue($event.target.value)"
          />
        </ion-item> -->
        <div class="color-picker">
          <div
            ref="Picker"
          />
        </div>
      </div>
      <div v-else-if="device.typeI === 'SmartPlug' || device.typeI === 'WallSwitch'">
        <div v-if="device.zigbeeModel === 'SmartPlug'">
          <img
            style="max-width: 200px;"
            src="../../assets/devices/SmartPlug.png"
          >
        </div>
        <div v-else-if="device.zigbeeModel === 'WallSwitch'">
          <img
            style="max-width: 200px;"
            src="../../assets/devices/WallSwitch.png"
          >
        </div>
        <ion-item
          color="transparent"
          lines="none"
          class="mt-10"
        >
          <ion-toggle
            v-model="switchgear"
            :disabled="$store.state.communication"
            style="margin: auto;"
            :checked="switchgear"
          />
        </ion-item>
        <div
          v-if="device.zigbeeModel === 'SmartPlug'"
          class="text--white mt-30"
        >
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="8"
            >
              {{ $t('Current consumption') }}:
            </ion-col> <ion-col class="text--primary">
              <span class="text--primary">{{ device.currentConsumption }}</span> W
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="8"
            >
              {{ $t('Total consumption') }}:
            </ion-col> <ion-col class="text--primary">
              <span class="text--primary">{{ device.totalConsumption }}</span> kW
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorMotion'">
        <div>
          <img
            style="max-width: 200px;"
            src="../../assets/devices/SensorMotion.png"
          >
        </div>
        <div class="text--white mt-10">
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Temperature') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.temp/100) }} °C
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Humidity') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.humi/100) }} %
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'Ok' : 'Replace Battery!' }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorMulti'">
        <div>
          <img
            style="max-width: 200px;"
            src="../../assets/devices/SensorMulti.png"
          >
        </div>
        <div class="text--white mt-10">
          <ion-row>
            <ion-col size="6">
              Status:
            </ion-col> <ion-col
              size="6"
              class="text--primary"
            >
              {{ deviceStatusMapper(device.status) }}
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Temperature') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.temp/100) }} °C
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Humidity') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.humi/100) }} %
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'OK' : $t('Replace Battery') }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorTemperatureOutdoor'">
        <div>
          <img
            style="max-width: 200px;"
            src="../../assets/devices/SensorTemperatureOutdoor.png"
          >
        </div>
        <div class="text--white mt-30">
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Temperature') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.temp/100) }} °C
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Humidity') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.humi/100) }} %
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'Ok' : 'Replace Battery!' }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else-if="device.typeI === 'SensorTemperatureIndoor'">
        <div>
          <img
            style="max-width: 200px;"
            src="../../assets/devices/SensorTemperatureIndoor.png"
          >
        </div>
        <div class="text--white mt-30">
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Temperature') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.temp/100) }} °C
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Humidity') }}:
            </ion-col> <ion-col class="text--primary">
              {{ Math.round(device.humi/100) }} %
            </ion-col>
          </ion-row>
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'OK' : $t('Replace Battery') }}
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div v-else>
        <div>
          <img
            style="max-width: 200px;"
            :src="require('../../assets/devices/' + device.typeI + '.png')"
          >
        </div>
        <div class="text--white mt-30">
          <ion-row class="flex-1">
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Status') }}:
            </ion-col> <ion-col class="text--primary">
              {{ deviceStatusMapper(device.status) }}
            </ion-col>
          </ion-row>
          <ion-row
            v-if="device.zigbeeModel !== 'IASWarning'"
            class="flex-1"
          >
            <ion-col
              class="text--right"
              size="6"
            >
              {{ $t('Battery') }}:
            </ion-col> <ion-col class="text--primary">
              {{ device.battery === 0 || device.battery === null ? 'Ok' : 'Replace Battery!' }}
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
    <div v-else-if="selectedView === '2'">
      <form
        class="login-form form--dark flex-1"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('deviceName') }}
          </ion-label>
          <ion-input
            v-model="deviceName"
            type="text"
            maxlength="11"
            enterkeyhint="done"
            autocapitalize="words"
            required
          />
        </ion-item>
      </form>
    </div>
    <div v-else-if="selectedView === '3'">
      <div
        v-if="loading"
        class="text--gray"
      >
        <ion-spinner
          color="light"
          name="crescent"
        /><br><br>
        {{ $t('Loading') }}....
      </div>
      <div v-else>
        <div
          v-if="deviceHistory.length"
          style="max-height: 400px"
          class="text--white text-left"
        >
          <div
            v-for="item in deviceHistory"
            :key="item"
          >
            <div
              v-if="device.zigbeeModel === 'SensorDoorWindow'"
              class="mb-10"
            >
              <div class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                <div class="mt-5">
                  {{ device.deviceName }} {{ $t('has been') }} {{ deviceStatusMapper(item.status) }}
                </div>
              </div>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'SensorWaterLeak'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('sensorWaterLeakLeakDetected') }}
              </p>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'SensorMotion'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('sensorMotionMovement') }}
              </p>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'SensorSmoke'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('sensorSmokeSmokeDetected') }}
              </p>
            </div>
            <div
              v-else-if="device.zigbeeModel === 'IASWarning'"
              class="mb-10"
            >
              <p class="text--white text-14">
                <span class="text--gray text-12">{{ dateFormatter(item.createdAt) }}</span><br>
                {{ $t('securityBreached') }} {{ $t('in') }} {{ deviceRoom(item.roomId) }}
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text--gray">
            {{ $t('No history recorded') }}
          </p>
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer
    class="footer"
  >
    <ion-button
      v-if="selectedView === '2'"
      :disabled="!deviceName"
      @click="applyDeviceSetting(device.ieee)"
    >
      <ion-label>
        {{ $t('save') }}
      </ion-label>
    </ion-button>
    <ion-button
      v-if="selectedView === '1' && !device.tunable && !device.temperatureSensor"
      fill="clear"
      color="transparent"
      @click="getHistory(device.ieee); selectedView = '3'"
    >
      <ion-label color="primary">
        {{ $t('Events History') }}
      </ion-label>
    </ion-button>
    <ion-button
      v-if="selectedView === '1'"
      @click="selectedView = '2'"
    >
      <ion-label>
        {{ $t('Edit Device') }}
      </ion-label>
    </ion-button>
    <ion-button
      fill="clear"
      color="transparent"
      @click="dismissPopover"
    >
      <ion-label color="primary">
        {{ $t('close') }}
      </ion-label>
    </ion-button>
  </ion-footer>
</template>

<script>
import { IonContent, alertController } from '@ionic/vue'
import { defineComponent } from 'vue'
import dayjs from 'dayjs'

import iro from '@jaames/iro'

export default defineComponent({
  name: 'Popover',
  components: { IonContent },
  props: {
    refreshData: { type: Function, default: null },
    device: { type: Object, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      selectedView: '1',
      deviceName: '',
      loading: true,
      selectedRoom: Number,
      backgrounds: '',
      identifying: false,
      value: Number,
      deviceHistory: []
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    },
    propertyRooms () {
      return this.$store.state.propertyRooms
    },
    lightLevel: {
      get () {
        const propertyDevices = this.$store.state.propertyDevices
        const device = propertyDevices.filter(obj => obj.ieee === this.device.ieee)
        return device[0].lightLevel
      },
      set (value) {
        const ieee = this.device.ieee
        const ep = this.device.ep
        console.log(ep)
        this.$store.dispatch('lightControl', { type: 'lightLevel', value, ieee, ep })
          .then(response => {
            if (response) {
              this.$store.commit('communication', false)
            }
          })
      }
    },
    colorTemp: {
      get () {
        const propertyDevices = this.$store.state.propertyDevices
        const device = propertyDevices.filter(obj => obj.ieee === this.device.ieee)
        return device[0].colorTemp
      },
      set (value) {
        const ieee = this.device.ieee
        const ep = this.device.ep
        this.$store.dispatch('lightControl', { type: 'colorTemp', value, ieee, ep })
          .then(response => {
            if (response) {
              this.$store.commit('communication', false)
            }
          })
      }
    },
    switchgear: {
      get () {
        const propertyDevices = this.$store.state.propertyDevices
        const device = propertyDevices.filter(obj => obj.ieee === this.device.ieee)
        let toggle
        if (device[0].switchgear === 'on') {
          toggle = true
        } else {
          toggle = false
        }
        return toggle
      },
      set (value) {
        const ieee = this.device.ieee
        const ep = this.device.ep
        let toggle
        if (value) {
          toggle = 'on'
        } else {
          toggle = 'off'
        }
        this.$store.dispatch('lightControl', { type: 'switchgear', value: toggle, ieee, ep })
          .then(response => {
            if (response) {
              this.$store.commit('communication', false)
            }
          })
      }
    }
  },
  created () {
    this.deviceName = this.device.deviceName
  },
  mounted () {
    if (this.device.zigbeeModel === 'SmartLightColorChangeable') {
      const colorPicker = iro.ColorPicker(this.$refs.Picker, {
        width: 150,
        layout: [
          {
            component: iro.ui.Wheel
          }
        ]
      })
      // color:change -input:end
      colorPicker.on('input:end', (color) => {
        this.changeValue(color)
      })
    }
  },
  methods: {
    identifyDevice (id) {
      this.$store.dispatch('identifyDevice', { deviceId: id }).then(resp => {
        if (resp) {
          this.identifying = true
          setTimeout(() => { this.identifying = false }, 30000)
        }
      })
    },
    getHistory (ieee) {
      this.loading = true
      this.$store.dispatch('getDeviceHistory', ieee).then(response => {
        console.log(response.data)
        if (this.device.zigbeeModel === 'SensorMotion') {
          const data = response.data.filter(item => item.type === 'Movement')
          this.deviceHistory = data
          this.loading = false
        } else {
          this.deviceHistory = response.data
          this.loading = false
        }
      })
    },
    dateFormatter (date) {
      const locale = this.$i18n.locale
      console.log(locale)
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      }

      return dayjs(date).locale(localeActive).format('dddd DD.MM.YYYY HH:mm')
    },
    applyDeviceSetting (ieee) {
      console.log(this.deviceName)
      const data = {
        ieee: ieee,
        deviceName: this.deviceName.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
      }
      this.$store.dispatch('updateDeviceSetting', data).then(response => {
        if (response) {
          this.refreshData(this.device.propertyId)
          this.dismissPopover()
        }
      })
    },
    deviceStatusMapper (status) {
      let statusResult = ''
      if (status === 'Initializing...') {
        statusResult = 'Initializing...'
      } else if (status === 'Open') {
        statusResult = this.$t('sensorDoorWindowOpened')
      } else if (status === 'Closed') {
        statusResult = this.$t('sensorDoorWindowClosed')
      } else if (status === 'No Movement') {
        statusResult = this.$t('sensorMotionNoMovement')
      } else if (status === 'Movement Detected') {
        statusResult = this.$t('sensorMotionMovement')
      } else if (status === 'No Smoke Detected') {
        statusResult = this.$t('sensorSmokeNoSmokeDetected')
      } else if (status === 'Smoke Detected') {
        statusResult = this.$t('sensorSmokeSmokeDetected')
      } else if (status === 'No Water Leak Detected') {
        statusResult = this.$t('sensorWaterLeakNoLeakDetected')
      } else if (status === 'Water Leak Detected!') {
        statusResult = this.$t('sensorWaterLeakLeakDetected')
      } else if (status === 'active') {
        statusResult = this.$t('smartSirenStatusActive')
      } else if (status === 'inactive') {
        statusResult = this.$t('smartSirenStatusSilent')
      }
      return statusResult
    },
    deviceRoom (roomId) {
      let result = ''
      if (roomId === 0) {
        result = 'Unassigned'
      } else {
        const rooms = this.$store.state.propertyRooms
        const found = rooms.find(element => element.id === roomId)
        result = found ? found.roomName : '--'
      }

      return result
    },
    removeDevice (device) {
      this.$store.dispatch('landlordRemoveDevice', { ieee: device.ieee, ep: device.ep, deviceId: device.id, propertyId: device.propertyId }).then(
        this.refreshData(this.device.propertyId),
        this.dismissPopover()
      )
    },
    dismissPopover () {
      this.close()
    },
    async removeAlertConfirm (device) {
      console.log(device)
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Remove this device?'),
          buttons: [
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            },
            {
              text: this.$t('Remove'),
              role: 'destructive',
              handler: () => {
                this.removeDevice(device)
              }
            }
          ]
        })
      return alert.present()
    },
    changeValue (color) {
      const colour = color.rgbString.replace(/[^\d,]/g, '').split(',')
      const r = parseInt(colour[0])
      const g = parseInt(colour[1])
      const b = parseInt(colour[2])
      const ieee = this.device.ieee
      const ep = this.device.ep
      const response = this.setRGBValue(r, g, b)
      console.log(response)
      this.$store.dispatch('lightControl', { type: 'color', value: response, ieee, ep })
        .then(response => {
          if (response) {
            this.$store.commit('communication', false)
          }
        })
    },
    setRGBValue (r, g, b) {
      let varR = (r / 255.0)
      let varG = (g / 255.0)
      let varB = (b / 255.0)

      if (varR > 0.04045) {
        varR = Math.pow(((varR + 0.055) / 1.055), 2.4)
      } else {
        varR = varR / 12.92
      }
      if (varG > 0.04045) {
        varG = Math.pow(((varG + 0.055) / 1.055), 2.4)
      } else {
        varG = varG / 12.92
      }
      if (varB > 0.04045) {
        varB = Math.pow(((varB + 0.055) / 1.055), 2.4)
      } else {
        varB = varB / 12.92
      }

      varR = varR * 100
      varG = varG * 100
      varB = varB * 100

      const X = varR * 0.4124 + varG * 0.3576 + varB * 0.1805
      const Y = varR * 0.2126 + varG * 0.7152 + varB * 0.0722
      const Z = varR * 0.0193 + varG * 0.1192 + varB * 0.9505

      const x = Math.round((X / (X + Y + Z)) * 65536)
      const y = Math.round((Y / (X + Y + Z)) * 65536)

      return { colorX: x, colorY: y }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
.buttonRoom {
  padding-left: 2px;
  padding-right: 2px;
}
.inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
    max-height: 44px;
}
.active {
    --background: #3F424A;
    color: colors(primary, base);
    max-height: 44px;
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}
.light-intensity {
  ion-range {
    --bar-background: linear-gradient(to right, black 0%, white 100%) !important;
  }
}
.color-temperature {
  ion-range {
    --bar-background: linear-gradient(to right, white 0%, lightyellow, yellow, orange 100%) !important;
  }
}
.color-range-changeable {
  ion-range {
    --bar-background: linear-gradient(to right, pink 0%, red, orange, lightgreen, green, lightblue, blue 100%) !important;
  }
}
.range.range {
  --bar-background-active: transparent;
  --knob-size: 24px;
  padding-left: 13px;
  padding-right: 13px;
  --bar-border-radius: 10px !important;
  --bar-height: 6px !important;
  padding-top: 0px !important;
}
.color-picker {
  margin-top: -15px;
  position: relative;
  left: 50%;
  transform: translateX(-25%);
}
</style>
