<template>
  <ion-page>
    <div class="background-main-view main-view">
      <ion-header class="ion-no-border">
        <ion-toolbar color="transparent">
          <div slot="start">
            <ion-button
              v-if="selectedView === '1'"
              fill="clear"
              color="transparent"
              size="large"
              class="return-back-button"
              @click="$router.go(-1)"
            >
              <img
                src="../assets/images/arrow-go-back-line.svg"
              >
            </ion-button>
            <ion-button
              v-else
              fill="clear"
              color="transparent"
              size="large"
              class="return-back-button"
              @click="selectedView = '1'"
            >
              <img
                style="width: 30px;"
                src="../assets/images/arrow-go-back-line.svg"
              >
            </ion-button>
          </div>
          <div slot="end">
            <ion-button
              fill="clear"
              color="transparent"
              size="large"
              class="return-back-button"
              @click="openApartmentMenu(apartmentDetails, apartmentDevices, buildingDetails)"
            >
              <i
                class="mdi mdi-tune text--white"
                style="font-size: 25px;"
              />
            </ion-button>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div
          v-if="loading"
        >
          <ion-progress-bar
            type="indeterminate"
          />
          <div class="mt-20">
            <ion-text style="font-size: 20px">
              Loading....
            </ion-text>
          </div>
        </div>
        <div
          v-else
          class="container2"
        >
          <ion-row class="mt-30">
            <ion-col class="text-left">
              <ion-label class="text--gray text--uppercase text-12 text--bold">
                Address
              </ion-label>
              <div class="text--white mt-5">
                {{ buildingDetails ? buildingDetails.buildingAddress : 'None' }}, {{ buildingDetails ? buildingDetails.buildingPostCode : 'None' }}<br>
                {{ buildingDetails ? buildingDetails.buildingCity : 'None' }}
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col
              size-xs="4"
              size-lg="2"
              size-xl="2"
              class="text-left"
            >
              <ion-label class="text--gray text--uppercase text-12 text--bold">
                Apartment Floor
              </ion-label>
              <div class="text--white mt-5">
                <i class="mdi mdi-stairs text--gray pr-5" />{{ apartmentDetails.propertyFloor }}
              </div>
            </ion-col>
            <ion-col
              size-xs="4"
              size-lg="2"
              size-xl="2"
              class="text-left"
            >
              <ion-label class="text--gray text--uppercase text-12 text--bold">
                Apartment Number
              </ion-label>
              <div class="text--white mt-5">
                <i class="mdi mdi-numeric-0-box text--gray pr-5" />{{ apartmentDetails.flatNumber }}
              </div>
            </ion-col>
            <ion-col
              size-xs="4"
              size-lg="4"
              size-xl="4"
              class="text-left"
            >
              <ion-label class="text--gray text--uppercase text-12 text--bold">
                Devices in aparment
              </ion-label>
              <div class="text--white mt-5">
                <i class="mdi mdi-numeric-0-box text--gray pr-5" />{{ apartmentDevices.length }}
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="mt-10">
            <ion-col>
              <ion-item
                v-if="apartmentResident === 'none'"
                class="tenant-detail rounded-corners"
                lines="none"
                button
                detail="false"
                @click="addTenant(apartmentDetails, apartmentDevices)"
              >
                <div
                  slot="start"
                  class="text--gray"
                >
                  <ion-text>
                    No tenants in this apartment
                  </ion-text>
                </div>
                <div slot="end">
                  <i
                    class="mdi mdi-plus-circle-outline text--primary text-22"
                  />
                </div>
              </ion-item>
              <ion-item
                v-else
                class="tenant-detail rounded-corners"
                lines="none"
                button
                detail="false"
                @click="addTenant(apartmentDetails, apartmentDevices); $event.stopPropagation()"
              >
                <div
                  slot="start"
                  class="text--white"
                  style="padding-right: 10vh;"
                  tappable
                >
                  <ion-text>
                    <span class="text-12 text--gray">Main tenant</span> <br>
                    <span class="text--primary">{{ apartmentResident.name }}</span>
                  </ion-text>
                </div>
                <div slot="end">
                  <a
                    tappable
                    class="mdi mdi-email text--gray text-22"
                    :href="`mailto:${apartmentResident.email}`"
                    @click="$event.stopPropagation()"
                  />
                  <a
                    tappable
                    class="mdi mdi-phone text--gray text-22 ml-20"
                    :href="`tel:${apartmentResident.phone}`"
                    @click="$event.stopPropagation()"
                  />
                </div>
              </ion-item>
            </ion-col>
          </ion-row>
          <div
            v-if="apartmentDetails.propertyGateway"
          >
            <ion-toolbar
              color="transparent"
              class="text-left ion-margin-bottom"
            >
              <ion-text class="text--white text--uppercase text-14 text--bold">
                Apartment Devices
              </ion-text>
              <ion-item
                slot="end"
                button
                detail="false"
                color="transparent"
                lines="none"
                @click="hasBeenCalled = false; getApartmentData($route.params.id); rotate();"
              >
                <span class="text--gray mr-10">Refresh data</span>
                <i
                  :class="rotateClass ? 'rotate' : ''"
                  class="mdi mdi-refresh mr-10 text--primary"
                  style="font-size: 30px;"
                />
              </ion-item>
            </ion-toolbar>
            <div
              class="connect-devices"
            >
              <ion-row>
                <ion-col
                  size-sm="6"
                  size-xs="6"
                  size-lg="3"
                  size-xl="2"
                >
                  <div
                    class="device rounded-corners"
                    :class="gatewayActive ? '' : 'alert'"
                  >
                    <div>
                      <div
                        class="device-img"
                        style="margin-top: 20px;"
                      >
                        <img :src="require('../assets/devices/gateway.png')">
                      </div>
                      <div style="margin-top: 10px;">
                        <h3>The Gateway</h3>
                      </div>
                    </div>
                    <div>
                      <p
                        class="text-12 text--primary"
                      >
                        <span>{{ gatewayActive ? 'Active' : 'Inactive' }}</span>
                      </p>
                    </div>
                  </div>
                </ion-col>
                <ion-col
                  v-for="device in apartmentDevices"
                  :key="device.id"
                  size-sm="6"
                  size-xs="6"
                  size-lg="3"
                  size-xl="2"
                  tappable
                >
                  <div
                    class="device rounded-corners"
                    :class="[device.active ? '' : 'alert', device.battery === 1 ? 'alert' : '', device.status === 'Water Leak Detected!' ? 'alert' : '', gatewayActive ? '' : 'alert']"
                  >
                    <div @click="openSettingPopover(device)">
                      <div
                        class="percentage text-right"
                        style="margin-right: 5px; margin-top: 5px;"
                      >
                        <ion-icon
                          v-if="device.battery === 0"
                          style="font-size: 20px;"
                          class="text--green"
                          :icon="batteryFullOutline"
                        />
                        <ion-icon
                          v-if="device.battery === 1"
                          class="text--danger"
                          style="font-size: 20px;"
                          :icon="batteryDeadOutline"
                        />
                      </div>
                      <div
                        class="device-img"
                        style="margin-top: 20px;"
                      >
                        <img :src="require('../assets/devices/' + device.typeI + '.png')">
                      </div>
                      <div style="margin-top: 10px;">
                        <h3>{{ device.deviceName }}</h3>
                      </div>
                    </div>
                    <div v-if="!device.active && gatewayActive">
                      <p
                        class="text-12 text--primary"
                      >
                        Device Disconnected
                      </p>
                      <div
                        class="text-12"
                        style="margin-top: -7px;"
                      >
                        {{ getDisconnectionDetail(device) ? new Date(Number(getDisconnectionDetail(device).startTime)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : 'Error refresh' }}
                        @ {{ getDisconnectionDetail(device) ? new Date(Number(getDisconnectionDetail(device).startTime)).toLocaleDateString('sv-SV') : '' }}
                      </div>
                    </div>
                    <div v-else-if="device.battery === 1 && gatewayActive">
                      <p
                        v-if="device.security || device.alarm"
                        class="text-12 text--primary"
                      >
                        Low Battery
                      </p>
                    </div>
                    <div v-else-if="!gatewayActive">
                      <p
                        class="text-12 text--primary"
                      >
                        Gateway offline
                      </p>
                    </div>
                    <div v-else>
                      <p
                        v-if="device.zigbeeModel === 'SensorMotion'"
                        class="text-12 text--primary"
                      >
                        <span v-if="device.temp">{{ device.temp ? Math.round(device.temp / 100) : 'L' }}°C / {{ device.humi ? Math.round(device.humi / 100) : 'L' }}%</span>
                        <span v-else>Waiting for the data</span>
                      </p>
                      <p
                        v-else-if="device.temperatureSensor"
                        class="text-12 text--primary"
                      >
                        <span v-if="device.temp">{{ device.temp ? Math.round(device.temp / 100) : 'L' }}°C / {{ device.humi ? Math.round(device.humi / 100) : 'L' }}%</span>
                        <span v-else>Waiting for the data</span>
                      </p>
                      <p
                        v-else
                        class="text-12 text--primary"
                      >
                        <span>{{ deviceStatusMapper(device.status) }}</span>
                      </p>
                    </div>
                  </div>
                </ion-col>
                <!-- <ion-col
                  v-if="platform.includes('desktop')"
                  size-sm="6"
                  size-xs="6"
                  size-lg="2"
                  size-xl="2"
                  style="position: relative; height: 20vh; padding: 15px;"
                >
                  <div
                    tappable
                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                    @click="addDeviceToApartment(apartmentDetails)"
                  >
                    <span class="text--gray">Add new device</span>
                    <div class="mt-10">
                      <i class="mdi mdi-plus-circle-outline text--primary text-22" />
                    </div>
                  </div>
                </ion-col> -->
              </ion-row>
              <ion-row style="min-height: 100px;" />
            </div>
            <ion-fab
              slot="fixed"
              vertical="bottom"
              horizontal="end"
              @click="addDeviceToApartment(apartmentDetails)"
            >
              <ion-fab-button>
                <ion-icon
                  color="dark"
                  :icon="addOutline"
                />
              </ion-fab-button>
            </ion-fab>
          </div>
          <div
            v-else
            class="mt-30 text--gray"
          >
            <ion-label class="text-18 text--primary">
              This apartment doesn't have a gateway yet
            </ion-label><br><br>
            <ion-text>
              This is propbably newly created appartment and before you<br>
              can add new devices please add gateway first
            </ion-text><br><br>
            <ion-text class="text--primary">
              Please note that this action can be done from mobile phone only
            </ion-text><br>
            <ion-button
              class="button mt-30"
              @click="addGatewayToApartment(apartmentDetails)"
            >
              Add gateway to this apartment
            </ion-button>
          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { IonProgressBar, modalController, actionSheetController, popoverController } from '@ionic/vue'
import { mapState } from 'vuex'
import { addOutline, batteryFullOutline, batteryDeadOutline } from 'ionicons/icons'

import LandlordAddTenantModal from '../components/Landlords/LandlordAddTenantModal.vue'
import LandlordGatewaySetupModal from '../components/Landlords/LandlordGatewaySetupModal.vue'
import landlordAddDeviceModal from '../components/Landlords/LandlordAddDeviceModal.vue'
import restartGatewayInApartment from '../components/Landlords/RestartGatwayInApartment.vue'
import landlordSettingPopover from '../components/Popovers/LandlordSettingPopOver.vue'
import editApartmentModal from '../components/Landlords/LandlordEditPropertyDetails.vue'

export default {
  components: {
    IonProgressBar
  },
  data () {
    return {
      loading: false,
      id: '',
      buildingId: '',
      apartmentResident: {},
      buildingDetails: {},
      apartmentDetails: {},
      apartmentDevices: [],
      gatewayActive: false,
      hasBeenCalled: false,
      selectedView: '1',
      created: false,
      rotateClass: false,
      disconnectedDevicesDetail: [],
      addOutline,
      batteryFullOutline,
      batteryDeadOutline
    }
  },
  computed: {
    ...mapState(['landlordBuildings', 'landlordProperties', 'platform'])
  },
  watch: {
    '$route.params.id': function (id) {
      if (this.$route.name === 'LandlordApartmentDetail') {
        if (id) {
          console.log('landlord-properties' + id)
          this.getApartmentData(id)
        }
      }
    }
  },
  created () {
    this.getApartmentData(this.$route.params.id)
  },
  methods: {
    rotate () {
      this.rotateClass = true
      setTimeout(() => { this.rotateClass = false }, 1000)
      console.log(this.rotateClass)
    },
    getApartmentData (apartmentId) {
      console.log('resfreshing')
      this.loading = true
      const id = Number(apartmentId)
      this.id = id
      this.$store.dispatch('getApartmentDetail', id).then(response => {
        if (response.status === 200) {
          console.log(response)
          this.apartmentResident = response.data.apartmentResident
          this.buildingDetails = response.data.building
          this.apartmentDetails = response.data.property
          this.apartmentDevices = response.data.devices
          this.apartmentDevices.forEach(item => {
            if (!item.active) {
              if (!this.hasBeenCalled) {
                this.hasBeenCalled = true
                this.$store.dispatch('getDisconnectionDetail', item.buildingId)
                  .then((resp) => {
                    this.disconnectedDevicesDetail = resp.data
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
            }
          })
          this.gatewayActive = response.data.property.gatewayActive
          console.log(this.apartmentDetails)
        }
      })
      this.loading = false
    },
    deviceRoom (roomId) {
      let result = ''
      if (roomId === 0) {
        result = 'Unassigned'
      } else {
        const rooms = this.$store.state.propertyRooms
        const found = rooms.find(element => element.id === roomId)
        result = found ? found.roomName : '--'
      }

      return result
    },
    deviceStatusMapper (status) {
      let statusResult = ''
      if (status === 'Initializing...') {
        statusResult = 'Initializing...'
      } else if (status === 'Open') {
        statusResult = 'Opened'
      } else if (status === 'Closed') {
        statusResult = 'Closed'
      } else if (status === 'No Movement') {
        statusResult = 'All is fine...'
      } else if (status === 'Movement Detected') {
        statusResult = 'Movement'
      } else if (status === 'No Smoke Detected') {
        statusResult = 'All is fine...'
      } else if (status === 'Smoke Detected') {
        statusResult = 'Smoke Detected!'
      } else if (status === 'No Water Leak Detected') {
        statusResult = 'All is fine...'
      } else if (status === 'Water Leak Detected!') {
        statusResult = 'Leak Detected!'
      } else if (status === 'active') {
        statusResult = 'Alarm in progress'
      } else if (status === 'inactive') {
        statusResult = 'Silent'
      }
      return statusResult
    },
    async addTenant (apartmentDetails, apartmentDevices) {
      const modal = await modalController
        .create({
          component: LandlordAddTenantModal,
          cssClass: 'devices-modal',
          showBackdrop: true,
          translucent: true,
          componentProps: {
            refreshData: (id) => this.getApartmentData(id),
            close: () => modal.dismiss(),
            apartment: apartmentDetails,
            devices: apartmentDevices
          }
        })
      await modal.present()
    },
    async addGatewayToApartment (apartment) {
      const modal = await modalController
        .create({
          component: LandlordGatewaySetupModal,
          cssClass: 'devices-modal',
          showBackdrop: true,
          translucent: true,
          componentProps: {
            refreshData: (id) => this.getApartmentData(id),
            close: () => modal.dismiss(),
            apartment: apartment
          }
        })
      await modal.present()
    },
    addDeviceToApartment (apartment) {
      return modalController
        .create({
          component: landlordAddDeviceModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            roomId: null,
            apartment: apartment,
            refreshData: (id) => this.getApartmentData(id),
            close: () => modalController.dismiss()
          }
        })
        .then(m => m.present())
    },
    async openResetGatewayModal (apartment) {
      const modal = await modalController
        .create({
          component: restartGatewayInApartment,
          cssClass: 'devices-modal',
          showBackdrop: true,
          translucent: true,
          componentProps: {
            refreshData: (id) => this.getApartmentData(id),
            close: () => modal.dismiss(),
            apartment: apartment
          }
        })
      await modal.present()
    },
    async openApartmentMenu (apartment, devices, buildingDetails) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'my-custom-class',
          buttons: [
            {
              text: this.$t('Edit apartment details'),
              handler: () => {
                this.editApartmentModal(apartment, buildingDetails)
              }
            },
            {
              text: this.$t('Remove all tenants'),
              role: 'destructive',
              handler: () => {
                this.addTenant(apartment, devices)
              }
            },
            {
              text: this.$t('Reset gateway'),
              role: 'destructive',
              handler: () => {
                this.openResetGatewayModal(apartment)
              }
            },
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              handler: () => {
                // console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()
      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    async openSettingPopover (device) {
      const popover = await popoverController
        .create({
          component: landlordSettingPopover,
          cssClass: 'popover-landlord',
          translucent: false,
          componentProps: {
            device,
            refreshData: (id) => this.getApartmentData(id),
            close: () => popover.dismiss()
          }
        })
      await popover.present()
    },
    async editApartmentModal (property, buildingDetails) {
      const modal = await modalController
        .create({
          component: editApartmentModal,
          cssClass: 'devices-modal',
          showBackdrop: true,
          translucent: true,
          componentProps: {
            refreshData: (id) => this.getApartmentData(id),
            close: () => modal.dismiss(),
            property: property,
            building: buildingDetails
          }
        })
      await modal.present()
    },
    getDisconnectionDetail (deviceDisconnected) {
      let result = { startTime: null }
      result = this.disconnectedDevicesDetail.filter(item => item.ieee === deviceDisconnected.ieee)[0]

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-fab {
  position: fixed;
  bottom: 50px;
  right: 20px;
}
ion-progress-bar {
    --background: gray;
}
.container2 {
  padding-left: 2vw;
  padding-right: 2vw;
}
.text-64 {
    font-size: 36px;
}
.search-bar-wrapper {
  max-width: 350px;
}
.search-bar {
  --box-shadow: none;
  color: white;
  --background: rgba(63, 66, 74, 0.8);
  border-radius: 5px;
}
.tenant-detail {
   @include bg-box-item;
   --min-height: 60px;
}
.room-card-devices-item-active {
  @include bg-box-room-device;
}
.room-card-devices-item-inactive {
  @include bg-box-room-device-inactive;
}
.button {
    min-height: 50px;
    max-width: 300px;
}
.connect-devices{
  padding:0 8px;
  .device{
    height: 180px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
    @include bg-box-room-device;
  h3{
    margin: 0;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
  }
  .alert {
    border: 1px solid red;
  }
}
  ion-title {
    font-weight: normal;
    letter-spacing: 0.6px;
  }
  .device {
    color: #fff;
    font-size: 14px;
    padding: 15px;
    height: 20vh;
    letter-spacing: 0.6px;
    font-weight: 400;
    // box-shadow: 0 0 2px #8e8b8b;
    position: relative;
    background-size: auto 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
  }
  .device img {
    height: 60px;
    text-align: center;
    margin: 0 auto;
  }

  .dim {
    border: 1px solid #3a3d44;
    box-shadow: none;
    h3 {
      color: #747373;
    }
  }
  .rotate {
    overflow: hidden;
    transition-duration: 0.8s;
    transition-property: transform;
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
</style>