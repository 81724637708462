<template>
  <div class="background--modal-landlord" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <div slot="start">
        <ion-button
          v-if="!editingTenant"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal(apartment.id)"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
        <ion-button
          v-else-if="editingTenant"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="leaveEditingTenant"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
      </div>
      <div>
        <ion-title
          class="text--white text-18"
        >
          Tenants
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="!created">
      <div
        v-if="!editingTenant"
        class="wrapper"
      >
        <div v-if="tenantsInApartment.length">
          <ion-list>
            <ion-item
              v-for="tenant in tenantsInApartment"
              :key="tenant"
              lines="none"
              class="tenant-detail rounded-corners mt-8"
            >
              <ion-text
                slot="start"
                class="text--white"
              >
                <span class="text-12 text--gray">{{ tenant.role === 'Tenant' ? 'Main Tenant' : 'Other User' }}</span> <br>
                <span class="text--primary">{{ tenant.name }}</span>
              </ion-text>
              <ion-text
                slot="end"
                class="text--gray text--uppercase text--bold text-14"
              >
                {{ tenant.active ? 'Active' : 'Pending' }}
              </ion-text>
              <div slot="end">
                <a
                  tappable
                  class="mdi mdi-email text--gray text-22"
                  :href="`mailto:${tenant.email}`"
                  @click="$event.stopPropagation()"
                />
                <a
                  tappable
                  class="mdi mdi-phone text--gray text-22 ml-20"
                  :href="`tel:${tenant.phone}`"
                  @click="$event.stopPropagation()"
                />
              </div>
            </ion-item>
          </ion-list>
          <ion-button
            v-if="tenantsInApartment.length"
            class="button-tenant mt-30"
            :disabled="loading"
            @click="editTenant"
          >
            <ion-spinner
              v-if="loading"
              name="crescent"
            />
            <span v-else>Edit tenant</span>
          </ion-button>
          <ion-button
            class="button-tenant mt-10"
            color="danger"
            @click="removeAlertAllTenants(apartment.id)"
          >
            Remove All Tenants
          </ion-button>
        </div>
        <div v-else-if="!tenantsInApartment.length">
          <form
            class="form--dark"
            :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
          >
            <ion-row
              class="ion-no-padding"
            >
              <ion-col
                class="ion-no-padding"
              >
                <ion-item lines="none">
                  <ion-label
                    class="label"
                    position="floating"
                  >
                    Tenant's email
                  </ion-label>
                  <ion-input
                    v-model="inviteTenantEmail"
                    type="email"
                    enterkeyhint="done"
                    required
                  />
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row
              class="ion-no-padding"
            >
              <ion-col
                class="ion-no-padding"
              >
                <ion-item lines="none">
                  <ion-label
                    class="label"
                    position="floating"
                  >
                    Tenant's Name and Surname
                  </ion-label>
                  <ion-input
                    v-model="inviteTenantName"
                    enterkeyhint="done"
                    type="text"
                    required
                  />
                </ion-item>
              </ion-col>
            </ion-row>
          </form>
          <div
            style="height: 10px;"
            class="text--danger"
          >
            {{ message }}
          </div>
          <ion-row class="mt-10">
            <ion-col>
              <ion-text class="text--primary">
                This is for inviting the tenant who will or is living in the apartment<br>
                You can invite the fisrts tenant as the main person and then he can invite the rest of the family or house mates<br>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-list style="max-width: 650px; margin: 30px auto 0 auto;">
            <div class="text-left">
              <ion-text class="text--gray text-14 text--uppercase text--bold">
                Devices to share with tenant
              </ion-text>
            </div>
            <div v-if="devices.length">
              <ion-item
                v-for="device in devices"
                :key="device"
                lines="none"
                color="transparent"
                class="device-item mt-8 rounded-corners"
              >
                <img
                  style="max-width: 40px;"
                  :src="require('../../assets/devices/' + device.typeI + '.png')"
                >
                <ion-text class="text--white text-14 pl-20">
                  {{ defaultDeviceNames(device.typeI) }}<br>
                  <div class="text--gray pt-5">
                    {{ device.deviceName }}
                  </div>
                </ion-text>
                <!-- <span
                  slot="end"
                  :class="deviceShared(device.id) ? 'text--primary text--bold' : 'text--white'"
                >{{ deviceShared(device.id) ? 'Shared' : '' }}</span> -->
                <ion-toggle
                  slot="end"
                  :value="deviceShared(device.id) ? false : true"
                  :checked="deviceShared(device.id) ? true : false"
                  @ionChange="shareDevice($event.target.value, device.id)"
                />
              </ion-item>
            </div>
            <div
              v-else
              class="pt-10 pb-10"
            >
              <ion-text>No devices found in apartment</ion-text>
            </div>
          </ion-list>
        </div>
        <ion-button
          v-if="!tenantsInApartment.length"
          class="button-tenant mt-30"
          :disabled="loading"
          @click="inviteTenant"
        >
          <ion-spinner
            v-if="loading"
            name="crescent"
          />
          <span v-else>Invite tenant</span>
        </ion-button>
      </div>
      <div
        v-else-if="editingTenant"
        class="wrapper"
      >
        <form
          class="form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
        >
          <ion-row
            class="ion-no-padding"
          >
            <ion-col
              class="ion-no-padding"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Tenant's email
                </ion-label>
                <ion-input
                  v-model="inviteTenantEmail"
                  type="email"
                  enterkeyhint="done"
                  required
                />
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row
            class="ion-no-padding"
          >
            <ion-col
              class="ion-no-padding"
            >
              <ion-item lines="none">
                <ion-label
                  class="label"
                  position="floating"
                >
                  Tenant's Name and Surname
                </ion-label>
                <ion-input
                  v-model="inviteTenantName"
                  enterkeyhint="done"
                  type="text"
                  required
                />
              </ion-item>
            </ion-col>
          </ion-row>
        </form>
        <div
          style="height: 10px;"
          class="text--danger"
        >
          {{ message }}
        </div>
        <ion-list style="max-width: 650px; margin: 0px auto 0 auto;">
          <div class="text-left">
            <ion-text class="text--gray text-14 text--uppercase text--bold">
              Shared devices
            </ion-text>
          </div>
          <div v-if="!loading">
            <div v-if="devices.length">
              <ion-item
                v-for="device in devices"
                :key="device"
                lines="none"
                color="transparent"
                class="device-item mt-8 rounded-corners"
              >
                <img
                  style="max-width: 40px;"
                  :src="require('../../assets/devices/' + device.typeI + '.png')"
                >
                <ion-text class="text--white text-14 pl-20">
                  {{ defaultDeviceNames(device.typeI) }}<br>
                  <div class="text--gray pt-5">
                    {{ device.deviceName }}
                  </div>
                </ion-text>
                <ion-toggle
                  slot="end"
                  :value="deviceShared(device.id) ? false : true"
                  :checked="deviceShared(device.id) ? true : false"
                  @ionChange="shareDevice($event.target.value, device.id)"
                />
              </ion-item>
            </div>
            <div
              v-else
              class="pt-10 pb-10"
            >
              <ion-text>No devices found in apartment</ion-text>
            </div>
            <ion-button
              class="button-tenant mt-20"
              :disabled="savingTenant"
              @click="saveEditingTenant"
            >
              <ion-spinner
                v-if="savingTenant"
                name="crescent"
              />
              <span v-else>Save tenant</span>
            </ion-button>
          </div>
          <div
            v-else
            class="mt-30"
          >
            <ion-spinner
              color="light"
              name="crescent"
            /><br><br>
            {{ $t('Loading') }}....
          </div>
        </ion-list>
      </div>
    </div>
    <div
      v-else
      style="margin-top: 20vh;"
    >
      <span class="text-64 text--white"><i
        class="mdi mdi-check-circle"
        style="color: lightgreen;"
      /> Tenant Invited</span>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'
import { alertController } from '@ionic/vue'
import { v4 as uuid } from 'uuid'
import { defaultDeviceNames } from '../../lib/variables'

export default defineComponent({
  name: 'Popover',
  props: {
    refreshData: { type: Function, default: null },
    close: { type: Function, default: null },
    apartment: { type: Object, default: () => {} },
    devices: { type: Object, default: () => {} }
  },
  data () {
    return {
      defaultDeviceNames: defaultDeviceNames,
      loading: false,
      created: false,
      selectedView: '1',
      tenantsInApartment: [],
      inviteTenantEmail: '',
      inviteTenantName: '',
      inviteTenantDevices: [],
      tenantName: '',
      message: '',
      editingTenant: false,
      savingTenant: false
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    console.log(this.apartment)
    this.getTenants()
  },
  methods: {
    dismissModal (apartmentId) {
      this.refreshData(apartmentId)
      this.close()
    },
    tenantDetail (tenant) {
      this.tenantName = tenant.name
    },
    getTenants () {
      const apartmentId = this.apartment.id
      this.$store.dispatch('getTenantsForApartment', apartmentId).then(response => {
        if (response.status === 200) {
          this.tenantsInApartment = response.data
          console.log(this.tenantsInApartment)
        }
      })
    },
    deviceShared (id) {
      return this.inviteTenantDevices.includes(id)
    },
    shareDevice (value, id) {
      if (value === 'true') {
        this.inviteTenantDevices.push(id)
      } else {
        const index = this.inviteTenantDevices.findIndex(item => item === id)
        this.inviteTenantDevices.splice(index, 1)
      }
    },
    inviteTenant () {
      if (!this.inviteTenantEmail) {
        this.message = 'Email is empty'
        setTimeout(() => { this.message = '' }, 2000)
      } else {
        this.loading = true
        const id = uuid()
        const data = {
          landlord: this.$store.state.userName,
          tenantEmail: this.inviteTenantEmail,
          tenantName: this.inviteTenantName,
          tenantsDevices: this.inviteTenantDevices,
          registrationToken: id,
          propertyId: this.apartment.id,
          role: 'Tenant',
          tenantAddress: {
            city: this.apartment.city,
            street: this.apartment.street,
            country: this.apartment.country,
            postCode: this.apartment.postCode,
            flatNumber: this.apartment.flatNumber,
            propertyNumber: this.apartment.propertyNumber
          }
        }
        this.$store.dispatch('inviteTenantToApartment', data).then(response => {
          if (response.status === 200) {
            this.created = true
            this.refreshData(this.apartment.id)
            setTimeout(() => { this.created = false; this.getTenants(); this.selectedView = '1'; this.loading = false }, 2000)
          } else {
            this.loading = false
            this.message = 'Something has failed, try that again'
          }
        })
      }
    },
    editTenant () {
      this.editingTenant = true
      this.loading = true
      this.inviteTenantEmail = this.tenantsInApartment[0].email
      this.inviteTenantName = this.tenantsInApartment[0].name

      const tenantId = this.tenantsInApartment[0].id
      this.$store.dispatch('getDevicesSharedToTenant', { tenantId }).then(response => {
        const resp = response.data
        const devices = []
        resp.forEach(item => {
          devices.push(item.id)
        })
        this.inviteTenantDevices = devices
        this.loading = false
      })
    },
    leaveEditingTenant () {
      this.editingTenant = false
      this.inviteTenantEmail = ''
      this.inviteTenantName = ''
      this.inviteTenantDevices = []
    },
    saveEditingTenant () {
      this.savingTenant = true
      const tenantId = this.tenantsInApartment[0].id
      this.$store.dispatch('saveEditedTenant', {
        tenantId,
        tenantEmail: this.inviteTenantEmail,
        tenantName: this.inviteTenantName,
        sharedDevices: this.inviteTenantDevices
      })
        .then(() => {
          this.inviteTenantEmail = ''
          this.inviteTenantName = ''
          this.inviteTenantDevices = []
          this.refreshData(this.apartment.id)
          this.savingTenant = false
          this.editingTenant = false
        })
    },
    // removeTenant (tenant) {
    //   this.$store.dispatch('removeTenantFromProperty', { tenantId: tenant.id }).then(resp => {
    //     this.getTenants()
    //   })
    // },
    removeAllTenants (apartmentId) {
      this.$store.dispatch('removeAllTenantsFromProperty', { apartmentId: apartmentId }).then(resp => {
        this.getTenants()
        this.dismissModal(apartmentId)
      })
    },
    // async removeAlertTenant (tenant) {
    //   const alert = await alertController
    //     .create({
    //       cssClass: 'remove-device',
    //       header: 'Removing tenant ' + tenant.name,
    //       buttons: [
    //         {
    //           text: this.$t('Cancel'),
    //           role: 'cancel',
    //           cssClass: 'secondary',
    //           handler: blah => {
    //           }
    //         },
    //         {
    //           text: this.$t('Remove'),
    //           role: 'destructive',
    //           handler: () => {
    //             this.removeTenant(tenant)
    //           }
    //         }
    //       ]
    //     })
    //   return alert.present()
    // },
    async removeAlertAllTenants (apartmentId) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: 'Remove all tenants in property?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: blah => {
              }
            },
            {
              text: 'Remove',
              role: 'destructive',
              handler: () => {
                this.removeAllTenants(apartmentId)
              }
            }
          ]
        })
      return alert.present()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
.wrapper {
    margin: auto;
}
.tenant-detail {
   @include bg-box-item;
   --min-height: 60px;
}
.button-tenant {
min-height: 50px;
}
.text-64 {
  font-size: 36px;
}
.h-65 {
   --min-height: 45px;
   @include bg-box-item;
}
</style>