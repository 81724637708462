<template>
  <div class="background--modal-landlord" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <div slot="start">
        <ion-button
          v-if="selectedView === '1' && '2'"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal(apartment.id)"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView = '1'"
        >
          <img
            src="../../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
      </div>
      <div>
        <ion-title
          class="text--white text-18"
        >
          Restart gateway in apartment
        </ion-title>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="!message">
        <div class="text--white text-18 text--bold">
          Reseting your gateway
        </div>
        <div
          class="mt-30 text--white text-left"
        >
          Please make sure you understand folowing: <br>
          <div
            color="transparent"
            lines="none"
            class="text--white mt-10 text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />This action will delete all apartment scenes that tenant created <br>
          </div>
          <div
            color="transparent"
            lines="none"
            class="text--white mt-10 text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />This action will delete all apartment rooms that tenant created <br>
          </div>
          <div
            color="transparent"
            lines="none"
            class="text--white mt-10 text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />This action will delete all devices withing the apartment <br>
          </div>
          <div
            color="transparent"
            lines="none"
            class="text--white mt-10 text-16"
          >
            <i class="mdi mdi-alert text--primary text-24 pr-5" />You will need to manually restart the gateway<br>
          </div>
          <div class="mt-30">
            <span class="text--white">Once process completed you will have to manually re-add the gateway</span>
          </div>
          <div
            class="mt-30"
          >
            <ion-item
              style="max-width: 300px;"
              color="transparent"
              lines="none"
              class="text--primary"
            >
              <ion-checkbox v-model="understand" /> <span class="pl-10">Understand and wish to continue</span>
            </ion-item>
            <ion-item
              style="max-width: 300px;"
              color="transparent"
              lines="none"
              class="text--primary"
            >
              <ion-checkbox v-model="gatewayReset" /> <span class="pl-10">I have restarted my gateway</span>
            </ion-item>
          </div>
          <div class="mt-30">
            <ion-button
              class="button-remove-gateway"
              :disabled="!understand || !gatewayReset || loading"
              color="danger"
              block
              @click="restartGatewayAlert(apartment)"
            >
              <ion-spinner
                v-if="loading"
                name="crescent"
              />
              <span v-else>Restart my gateway</span>
            </ion-button>
          </div>
        </div>
      </div>
      <div
        v-else
        style="margin-top: 20vh;"
      >
        <span class="text-64 text--white"><i
          class="mdi mdi-alert-circle"
          style="color: red;"
        /> {{ message }}</span>
      </div>
    </transition>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue'
import { alertController } from '@ionic/vue'

export default defineComponent({
  name: 'RestartGatewayInApartment',
  props: {
    refreshData: { type: Function, default: null },
    close: { type: Function, default: null },
    apartment: { type: Object, default: () => {} }
  },
  data () {
    return {
      loading: false,
      selectedView: '1',
      message: '',
      understand: false,
      gatewayReset: false
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    dismissModal (apartmentId) {
      this.refreshData(apartmentId)
      this.close()
    },
    restartGateway (apartment) {
      this.$store.dispatch('landlordRestartGatewayInApartment', apartment)
        .then(resp => {
          this.loading = false
          this.dismissModal(apartment.id)
        })
        .catch((error) => {
          console.log(error)
          this.message = 'Something has failed, please try that again'
          setTimeout(() => { this.message = '' }, 2000)
          this.loading = false
        })
    },
    async restartGatewayAlert (apartment) {
      this.loading = true
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: 'Restart gateway?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                this.loading = false
              }
            },
            {
              text: 'Restart',
              role: 'destructive',
              handler: () => {
                this.restartGateway(apartment)
              }
            }
          ]
        })
      return alert.present()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
.wrapper {
    margin: auto;
}
.tenant-detail {
   @include bg-box-item;
   --min-height: 60px;
}
.button-remove-gateway {
height: 50px;
}
.text-64 {
  font-size: 36px;
}
</style>