<template>
  <div class="background--modal-landlord" />
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar color="transparent">
        <ion-button
          v-if="selectedView === 1"
          slot="start"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="dismissModal(apartment)"
        >
          <img src="../../assets/images/arrow-go-back-line.svg">
        </ion-button>
        <ion-button
          v-else-if="selectedView < 4"
          slot="start"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView--"
        >
          <img src="../../assets/images/arrow-go-back-line.svg">
        </ion-button>
        <ion-title class="text--white text-18 text--400">
          Connect device
        </ion-title>
        <ion-button
          v-if="selectedView === 1"
          slot="end"
          fill="clear"
          :disabled="!selectedDevice"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView++"
        >
          <ion-text
            class="text-16 text--400"
            color="primary"
          >
            Next
          </ion-text>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div v-if="platform.includes('desktop')">
        <div style="margin-top: 20vh">
          <ion-text class="text--primary text-22">
            <i
              class="mdi mdi-alert-circle"
              style="font-size: 30px;"
            />
            This action can be done only on mobile phone
          </ion-text>
          <div class="mt-30">
            <ion-button
              style="max-width: 300px;"
              @click="dismissModal(apartment)"
            >
              Dismiss
            </ion-button>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="selectedView === 1">
          <ion-row class="ion-margin-top">
            <ion-col
              v-for="device in availableDevices"
              :key="device.id"
              size-xl="2"
              size-lg="4"
              size-md="4"
              size-sm="4"
              size-xs="6"
            >
              <div
                class="device rounded-corners"
                :class="selectedDeviceTypeI === device.typeI ? 'selected' : 'none'"
                @click="selectedDevice = device.zigbeeModel; selectedDeviceTypeI = device.typeI"
              >
                <div class="percentage text-right" />
                <div>
                  <div class="device-img">
                    <img :src="require('../../assets/devices/' + device.typeI + '.png')">
                  </div>
                  <p class="text-12 text--gray" />
                  <h3>
                    {{ device.name }}
                  </h3>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
        <div v-else-if="selectedView === 2">
          <div
            class="connectDevices"
            style="margin-top: -25px;"
          >
            <div
              class="connecting-device"
            >
              <img :src="require('../../assets/devices/' + selectedDeviceTypeI + '.png')">
            </div>
            <form
              class="login-form form--dark flex-1"
              :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
            >
              <ion-item
                :class="enterName ? 'enter-name-error' : ''"
                lines="none"
              >
                <ion-label position="floating">
                  Device Name
                </ion-label>
                <ion-input
                  v-model="deviceName"
                  type="text"
                  maxlength="13"
                  enterkeyhint="done"
                  autocapitalize="words"
                  required
                />
              </ion-item>
            </form>
            <div class="text-10">
              <ul v-if="selectedDeviceTypeI === 'SensorMotion' || selectedDeviceTypeI === 'SensorWaterLeak' || selectedDeviceTypeI === 'SensorMulti' || selectedDeviceTypeI === 'SensorTemperatureIndoor'">
                <li>{{ $t('SensorMotionSensorWaterLeakStep1') }}</li>
                <li>{{ $t('SensorMotionSensorWaterLeakStep2') }}</li>
                <li>{{ $t('SensorMotionSensorWaterLeakStep3') }}</li>
                <li>{{ $t('SensorMotionSensorWaterLeakStep4') }}</li>
                <li>{{ $t('SensorMotionSensorWaterLeakStep5') }}</li>
                <li>{{ $t('SensorMotionSensorWaterLeakStep6') }}</li>
              </ul>
              <ul v-else-if="selectedDeviceTypeI === 'SensorSmoke'">
                <li>{{ $t('SensorSmokeStep1') }}</li>
                <li>{{ $t('SensorSmokeStep2') }}</li>
                <li>{{ $t('SensorSmokeStep3') }}</li>
                <li>{{ $t('SensorSmokeStep4') }}</li>
              </ul>
              <ul v-else-if="selectedDeviceTypeI === 'SensorDoorWindow'">
                <li>{{ $t('SensorDoorWindowStep1') }}</li>
                <li>{{ $t('SensorDoorWindowStep2') }}</li>
                <li>{{ $t('SensorDoorWindowStep3') }}</li>
                <li>{{ $t('SensorDoorWindowStep4') }}</li>
                <li>{{ $t('SensorDoorWindowStep5') }}</li>
                <li>{{ $t('SensorDoorWindowStep6') }}</li>
              </ul>
              <ul v-else-if="selectedDeviceTypeI === 'SmartPlug'">
                <li>{{ $t('SmartPlugStep1') }}</li>
                <li>{{ $t('SmartPlugStep2') }}</li>
                <li>{{ $t('SmartPlugStep3') }}</li>
                <li>{{ $t('SmartPlugStep4') }}</li>
              </ul>
              <ul v-else-if="selectedDeviceTypeI === 'SensorTemperatureOutdoor'">
                <li>{{ $t('SensorTemperatureOutdoorStep1') }}</li>
                <li>{{ $t('SensorTemperatureOutdoorStep2') }}</li>
                <li>{{ $t('SensorTemperatureOutdoorStep3') }}</li>
                <li>{{ $t('SensorTemperatureOutdoorStep4') }}</li>
              </ul>
              <ul v-else-if="selectedDeviceTypeI === 'IASWarning'">
                <li>{{ $t('IASWarningStep1') }}</li>
                <li>{{ $t('IASWarningStep2') }}</li>
                <li>{{ $t('IASWarningStep3') }}</li>
                <li>{{ $t('IASWarningStep4') }}</li>
              </ul>
              <ul v-else>
                <li>{{ $t('Please consult devices manual to start pairing process') }}</li>
                <!-- <li>{{ $t('IASWarningStep2') }}</li>
            <li>{{ $t('IASWarningStep3') }}</li> -->
              </ul>
            </div>
            <div class="connect-device-content">
              <ion-item
                class="center"
                color="transparent"
                lines="none"
                :detail="false"
                button
                @click="checkedAllDone = !checkedAllDone"
              >
                <div slot="start" />
                <div slot="start" />
                <ion-label>
                  Confirm the device is ready
                </ion-label>
                <ion-checkbox
                  slot="start"
                  :checked="checkedAllDone"
                  color="primary"
                />
              </ion-item>
              <ion-button
                type="button"
                class="primary mt-20"
                :disabled="!checkedAllDone"
                @click="addApartmentDevice(apartment)"
              >
                Connect
              </ion-button>
            </div>
          </div>
        </div>
        <div v-else-if="selectedView === 3">
          <div
            class="loader-wrapper"
            style="margin-top: 20vh"
          >
            <div class="back-circle-loader">
              <div
                v-if="deviceAddStatus === null"
                class="loader-anim"
              />
              <div class="inner-loader" />
              <div class="inner-loader-frame" />
            </div>
            <div class="laoder-percentage">
              <span
                v-if="deviceAddStatus === 'success'"
                style="font-size: 24px;"
                class="text--primary"
              >Success</span>
              <span
                v-else-if="deviceAddStatus === 'error'"
                style="font-size: 24px;"
                class="text--danger"
              >
                Error
              </span>
              <span v-else>{{ progress }}%</span>
            </div>
          </div>
          <div>
            <p class="text--white mt-30">
              Adding the device.. hold on
            </p>
          </div>
          <ion-button
            v-if="deviceAddStatus === 'success'"
            class="mt-30"
            @click="dismissModal(apartment)"
          >
            Hurray!! Device Added
          </ion-button>
          <ion-button
            v-if="deviceAddStatus === 'error'"
            class="mt-30"
            @click="resetAddingDevice"
          >
            Something has failed, try it again
          </ion-button>
          <ion-button
            v-if="deviceAddStatus === 'error'"
            class="mt-30"
            color="medium"
            @click="dismissModal(apartment)"
          >
            Close
          </ion-button>
          <ion-text v-if="deviceAddStatus === 'error'">
            Please make sure you reset the device
          </ion-text>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { closeOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'

import { mapState } from 'vuex'

export default defineComponent({
  name: 'AddDeviceModal',
  props: {
    refreshData: { type: Function, default: null },
    apartment: { type: Object, default: null },
    roomId: { type: Number, default: null },
    close: { type: Function, default: null }
  },
  data () {
    return {
      closeOutline,
      enterName: false,
      selectedRoom: null,
      selectedDevice: '',
      selectedDeviceTypeI: '',
      deviceName: '',
      progress: 0,
      selectedView: 1,
      checkedAllDone: false,
      deviceAddStatus: null,
      availableDevices: [
        { zigbeeModel: 'SensorDoorWindow', typeI: 'SensorDoorWindow', name: this.$t('doorWindowSensor') },
        { zigbeeModel: 'SensorMotion', typeI: 'SensorMulti', name: this.$t('multiSensor') },
        { zigbeeModel: 'SensorMotion', typeI: 'SensorMotion', name: this.$t('motionSensor') },
        { zigbeeModel: 'SensorWaterLeak', typeI: 'SensorWaterLeak', name: this.$t('leakSensor') },
        { zigbeeModel: 'SmartLightColorDimmable', typeI: 'SmartLightColorDimmable', name: this.$t('dimmableBulb') },
        { zigbeeModel: 'SmartLightColorChangeable', typeI: 'SmartLightColorChangeable', name: this.$t('colorBulb') },
        { zigbeeModel: 'SensorSmoke', typeI: 'SensorSmoke', name: this.$t('smokeSensor') },
        // { zigbeeModel: 'WallSwitch', name: this.$t('wallSwitch') },
        { zigbeeModel: 'SmartPlug', typeI: 'SmartPlug', name: this.$t('smartPlug') },
        { zigbeeModel: 'IASWarning', typeI: 'IASWarning', name: this.$t('smartSiren') },
        { zigbeeModel: 'SensorTemperatureOutdoor', typeI: 'SensorTemperatureOutdoor', name: this.$t('sensorTemperatureOutdoor') },
        { zigbeeModel: 'SensorTemperatureOutdoor', typeI: 'SensorTemperatureIndoor', name: this.$t('sensorTemperatureIndoor') }
      ]
    }
  },
  computed: {
    ...mapState(['statusBucketLandlord']),
    platform () {
      return this.$store.state.platform
    },
    keyboardStatus () {
      return this.$store.state.keyboardStatus
    }
  },
  watch: {
    statusBucketLandlord: {
      handler: function (newVal, oldVal) {
        this.statusBucketChanged()
        console.log('bucket changed')
      },
      deep: true
    }
  },
  created () {
    if (this.roomId) {
      this.selectedRoom = this.roomId
    }
    this.progress = 0
    this.checkedAllDone = false

    console.log(this.apartment.id)
  },
  methods: {
    statusBucketChanged () {
      console.log('checking status bucket for cahnges')
      this.statusBucketLandlord.forEach(item => {
        const propertyId = Number(item.propertyId)
        if (propertyId === this.apartment.id) {
          if (item.type === 'landlordDeviceAddError') {
            this.deviceAddStatus = 'error'
            console.log('bucket error')
          } else if (item.type === 'landlordDeviceAddSuccess') {
            console.log('bucket success')
            this.deviceAddStatus = 'success'
          }
        }
      })
    },
    dismissModal (apartment) {
      this.progress = 0
      this.selectedDevice = ''
      this.checkedAllDone = false
      this.deviceAddStatus = null
      this.refreshData(apartment.id)
      this.close()
    },
    async progressPlus (number) {
      const add = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms))
      }
      for (let i = 0; i < number; i++) {
        await add(350)
        this.progress++
      }
    },
    addApartmentDevice (apartment) {
      if (!this.selectedDevice || !this.deviceName) {
        console.log('missing data')
        this.enterName = true
        setTimeout(() => {
          this.enterName = false
        }, 2000)
      } else {
        let tunable
        let security
        let alarm
        let status
        let temperatureSensor
        if (this.selectedDevice === 'IASWarning') {
          status = 'inactive'
        } else if (this.selectedDevice === 'SensorMotion') {
          status = 'No Movement'
        } else if (this.selectedDevice === 'SensorDoorWindow') {
          status = 'Closed'
        } else if (this.selectedDevice === 'SensorSmoke') {
          status = 'No Smoke Detected'
        } else if (this.selectedDevice === 'SensorWaterLeak') {
          status = 'No Water Leak Detected'
        } else {
          status = 'No Status'
        }
        if (
          this.selectedDevice === 'SmartPlug' ||
        this.selectedDevice === 'WallSwitch' ||
        this.selectedDevice === 'SmartLightColorDimmable'
        ) {
          tunable = true
        } else {
          tunable = false
        }
        if (
          this.selectedDevice === 'SensorMotion' ||
        this.selectedDevice === 'SensorDoorWindow'
        ) {
          security = true
        } else {
          security = false
        }
        if (this.selectedDevice === 'SensorSmoke' ||
        this.selectedDevice === 'SensorWaterLeak') {
          alarm = true
        } else {
          alarm = false
        }
        if (this.selectedDeviceTypeI === 'SensorTemperatureOutdoor' || this.selectedDeviceTypeI === 'SensorTemperatureIndoor') {
          temperatureSensor = true
        } else {
          temperatureSensor = false
        }
        const device = {
          buildingId: apartment.buildingId,
          landlordId: apartment.landlordId,
          id: null,
          propertyRoomId: 0,
          appUserIds: this.$store.state.userId + ',',
          zigbeeModel: this.selectedDevice,
          deviceName: this.deviceName,
          battery: 0,
          typeI: this.selectedDeviceTypeI,
          tunable,
          security,
          temperatureSensor,
          alarm,
          status,
          ieee: null,
          active: false,
          landlordsDevice: true
        }
        this.$store.dispatch('landlordAddPropertyDevice', { device, apartmentId: apartment.id })
        this.selectedView++
        this.progressPlus(100)
      }
    },
    resetAddingDevice () {
      this.selectedView = 1
      this.progress = 0
      this.deviceAddStatus = null
      this.checkedAllDone = false
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import '~@/theme/_layouts';
@import '~@/theme/_backgrounds';
  .device {
    height: 160px;
    @include bg-box-room-device;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
  h3{
    margin: 0;
    margin-top:30px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
  }
  .selected {
    background: #3F424A;
    border: 1px solid colors(primary, base);
    h3 {
      color: colors(primary, base);
    }
  }
.buttonRoom {
  padding-left: 2px;
  padding-right: 2px;
}
.inactive {
    --background: rgba(44, 48, 54, 0.6);
    color: white;
    max-height: 44px;
}
.active {
    --background: #3F424A;
    color: colors(primary, base);
    max-height: 44px;
    border: 1px solid colors(primary, base);
    border-radius: 5px;
}
  .connectDevices {
    .connecting-device {
      padding-top: 5px;
      padding-bottom: 0px;
      img {
        max-width: 100px;
      }
    }
    .connect-device-content {
      ion-item {
        --padding-start: 0;
        --inner-padding-end: 0;
        ion-label {
          white-space: normal;
          color: #fff;
          letter-spacing: 0.6px;
          font-size: 14px;
          background: transparent;
        }
      }
    }
  }
      ul {
      list-style: none;
      padding-left: 0;
      padding-left: 0px;
      padding-right: 0px;
      li {
        color: #fff;
        text-align: left;
        letter-spacing: 0.6px;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 15px;
        display: flex;
      }
      li:before {
        content: "✓";
        color: #ffd4a4;
        padding-right: 15px;
        font-size: 18px;
      }
    }
.loader-wrapper {
  position: relative;
}
.back-circle-loader {
  position: relative;
  z-index: -1;
  height: 214px;
  width: 214px;
  background: linear-gradient(180deg, #ffd5a433 0%, #f4b87421 100%);
  // opacity: 0.1;
  border-radius: 50%;
  display: inline-block;
}
.loader-anim {
  position: relative;
  top: 7px;
  left: 7px;
  border: 8px solid #f3f3f300;
  border-top: 8px solid #ffd4a4;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  -webkit-animation: spinAnim 1s linear infinite;
  animation: spinAnim 1s linear infinite;
}
@keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinAnim {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.laoder-percentage {
  color: white;
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 52px;
  line-height: 61px;
}
.inner-loader {
  z-index: 3;
  position: absolute;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 164px;
  width: 164px;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  filter: drop-shadow(0px 7px 11px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(42px);
  border-radius: 50%;
}
.inner-loader-frame {
  position: absolute;
  z-index: 5;
  top: 25px;
  left: 25px;
  // transform: translate(-50%, -50%);
  height: 165px;
  width: 165px;
  border-radius: 50%;
  background: radial-gradient(100% 100% at 0% 0%, rgba(206, 206, 206, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1px solid rgba(150, 150, 150, 0.205);
}
.keyboard-opened {
  img {
    transition: 0.4s;
    height: 0vh;
    opacity: 0;
  }
}
.keyboard-closed {
  img {
    transition: 0.4s;
    opacity: 1;
    height: 25vh;
  }
}
ion-button {
  min-height: 50px;
}
.enter-name-error {
  outline: 1px solid red;
  color: red;
}
</style>
